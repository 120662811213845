@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400&display=swap');

@font-face {
  font-family: "New York Large Regular Italic";
  src: local("New York Large Regular Italic"),
    url("components/New York Large Regular Italic.otf");
}
@font-face {
  font-family: "New York Extra Large Medium";
  src: local("New York Extra Large Medium"),
    url("components/New York Extra Large Medium.otf");
}
@font-face {
  font-family: "San Francisco Text Regular";
  src: local("San Francisco Text Regular"),
    url("components/San Francisco Text Regular.otf");
}
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Source Sans Pro", "Libre Caslon Text", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.mapWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid black;
}
.map {
  flex: 1 0;
  display: flex;
  height: 100%;
}
.map__reactleaflet {
  flex: 1 0;
  height: 100%;
}
.app {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.leaflet-container {
  height: 100vh;
  font-size: 0.7em;
  font-family: "avenir heavy";
}
.popup-marker {
  color: #61857d;
  /* background-color: #61857d; /* Green */
}
.infoCard {
  display: block;
  position: absolute;
  background-color: #fff;
  border-radius: 2px;
  padding: 15px 20px;
  width: 60%;
  height: 60%;
  z-index: 1000;
  top: 20vh;
  left: 14.5vw;
}
.onboardingCard {
  display: block;
  position: relative;
  background-color: #fff;
  border-style: solid;
  border-color: #b9d9b9; /* lightSage*/
  border-width: 2px;
  padding: 8px 16px 8px 16px;
  width: 85%;
  height: 93%;
  top: 1.5%;
  left: 2.4%;
  z-index: 2002;
}
.onboardingBackground {
  z-index: 2001;
  border-radius: 2px;
  display: block;
  position: absolute;
  background-color: #fff;
  width: 90vw;
  height: 80vh;
  top: 10vh;
  left: 5vw;
  opacity: 1;
}
.swishCard {
  display: block;
  position: relative;
  background-color: #fff;
  border-style: solid;
  border-color: #b9d9b9; /* lightSage*/
  border-width: 2px;
  padding: 8px 16px 8px 16px;
  width: 85%;
  height: 93%;
  top: 1.5%;
  left: 2.4%;
  z-index: 3002;
}
.swishBackground {
  z-index: 3001;
  border-radius: 2px;
  display: block;
  position: absolute;
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  opacity: 1;
}
.swishTextWrapper {
  position: absolute;
  top: 40vh;
  width: 80vw;
  left: 10vw;
}
.tooltipBox {
  padding: 0px 0px 8px 0px;
}
img.bgImage {
  display: block;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  z-index: 2000;
}
img.gifImg {
  display: block;
  position: absolute;
  top: 5vh;
  height: 30vh;
  left: 22vw;
  z-index: 3005;
}
.button {
  position: absolute;
  bottom: 3vh;
  left: 10%;
  width: 80%;
  flex: auto;
  background-color: #5f7e5f; /* SlateGreen */
  border: none;
  color: white;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.9em;
  border-radius: 4px;
  font-family: "New York Extra Large Medium";
  cursor: pointer;
  opacity: 1;
  margin: auto;
}
.button:hover {
  opacity: 0.8;
}
.button::after {
  background-color: #c7a65d;
  border: none;
}
.latin {
  font-family: "New York Large Regular Italic";
  font-size: 1em;
  color: #5f7e5f; /* SlateGreen */
  text-align: left;
}
.name {
  font-family: "New York Extra Large Medium";
  font-size: 2em;
  line-height: 0.5;
  color: #5f7e5f; /* SlateGreen */
  text-align: left;
}
p {
  font-family: "San Francisco Text Regular";
  font-size: 0.8em;
  color: #575e57; /*darkSageThree*/
  text-align: center;
  line-height: 1.5;
}
p.tooltip {
  text-align: left;
  font-weight: normal;
  font-size: 1.2em;
}
p.bold {
  font-weight: bold;
}
p.italic {
  font-style: italic;
  font-size: 0.89em;
  line-height: 2;
}

.p1 {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
}

.p2 {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.p3 {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

h1 {
  font-family: 'Libre Caslon Text';
  font-style: normal;
  font-size: 20px;
  color: var(--green-heading);
  text-align: center;
  line-height: 28px;
  font-weight: normal;
}

h2 {
  font-family: 'Libre Caslon Text';
  font-style: normal;
  font-size: 18px;
  text-align: center;
  line-height: 26px;
  font-weight: normal;
}

h3 {
  font-family: 'Libre Caslon Text';
  font-style: normal;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  font-weight: normal;
}

h4 {
  font-family: 'Libre Caslon Text';
  font-style: normal;
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  font-weight: normal;
}

.l1 {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
}

.l2 {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.l3 {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
}

.mushroomWarning {
  color: #ff5500;
  font-style: italic;
  font-size: 1em;
}
a {
  font-family: "San Francisco Text Regular";
  font-size: 1em;
  color: #575e57; /*darkSageThree*/
  font-weight: bold;
  text-align: center;
}
a.tooltip {
  color: #5f7e5f; /* SlateGreen */
  text-align: left;
  font-weight: normal;
  font-size: 1.2em;
}
a.tooltip_observation {
  color: #5f7e5f; /* SlateGreen */
  text-align: left;
  font-weight: normal;
  font-size: 1em;
}
span.cluster-icon-text {
  font-size: 2em;
  color: red;
  position: absolute;
}
span {
  font-size: 0.9em;
}
hr {
  border-color: #d5ead5;
}
:root {
  --amplify-primary-color: #5f7e5f; /* SlateGreen */
  --amplify-primary-tint: #5f7e5f; /* SlateGreen */
  --amplify-primary-shade: #5f7e5f; /* SlateGreen */
  --amplify-font-family: "New York Extra Large Medium";
  --green-primary: #687566;
  --green-heading: #425A42;
  --green-bright-and-button: #5F7E5F;
  --green-latin-detail: #7B967B;
  --green-detail-and-divider: #E4EBE4;
  --green-latin-detail-20percent: rgba(123, 150, 123, 0.2);
  --grey-white: #FFFFFF;
  --grey-light: #F8F8F8;
  --grey-base-text: #454444;
  --grey-light-text: #000000;

}
.sign-up-form-footer {
  color: #fff;
}
.leaflet-popup-content-wrapper {
  border-radius: 4px;
}
.userNavButton {
  position: absolute;
  z-index: 1010;
  bottom: 13vh;
  right: 1vw;
  width: 50px;
}
.hamburgerButton {
  position: absolute;
  z-index: 1502;
  top: 2vh;
  left: 2vw;
  background-color: !#fff;
}
.settingButton {
  position: absolute;
  z-index: 1512;
  top: 2vh;
  left: 2vw;
}
.hamburgerWrapper {
  display: block;
  position: absolute;
  background-color: #fff;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  z-index: 1500;
}
.settingWrapper {
  display: block;
  position: absolute;
  background-color: #fff;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  z-index: 1510;
}
hr.hamburgerLine {
  width: 90%;
}
h1.hamburgerText {
  padding: 5px 20vw;
  text-align: left;
  font-size: 1em;
  font-style: normal;
}
p.hamburgerText {
  padding: 4px 5vw;
  font-size: 0.95em;
  text-align: left;
}
p.hamburgerSmall {
  margin: 24px 0 0 0;
  font-size: 0.75em;
  text-align: left;
}
p.swishText {
  padding: 4px 5vw;
  font-size: 16px;
  text-align: left;
}
.signoutButton {
  position: absolute;
  z-index: 1501;
  bottom: 5vh;
  width: 90%;
  margin: 0px 5vw;
}
.swishBox {
  display: block;
  position: relative;
  background-color: #d5ead5;
  border-radius: 4px;
  z-index: 1518;
  margin: 24px 16px 0 16px;
}
.swishBox-small {
  display: block;
  position: relative;
  width: 90%;
  left: 5vw;
  height: 20%;
  background-color: #d5ead5;
  border-radius: 4px;
  z-index: 1518;
  padding: 8px 0px;
}
.radio {
  left: 135px;
}
.radio2 {
  left: 74px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
}

.container .hamburgerLine {
  margin: 24px 0 0 0;
  width: auto;
}

.container .hamburgerText {
  padding: 0;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.item p {
  padding: 0;
  margin: 0;
}

p.item {
  padding: 0;
  margin: 0;
}

.item span span input {
  width: 24px;
  height: 24px;
  top: auto;
  left: auto;
}

.item span {
  padding: 0;
}

.item-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.margin-top {
  margin-top: 24px !important;
}

.margin-bot-0 {
  margin-bottom: 0;
}

.swish-container {
  display: inline-block;
  flex-direction: column;
  justify-content: space-between;
  margin: 16px;
}

.p2 {
  font-size: 16px;
}

.swish-link {
  position: unset;
  bottom: 0;
  left: unset;
  width: 100%;
  flex: unset;
}
